import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BridgeResultMessagesBase } from '@ortho-next/nextray-core';
import { environment } from '../../environments/environment';
import { LanguageService, LoaderService } from '../core';
import { CanvasService, MainCanvasLoaderService, UserService } from '../services';
import { BaseComponent, ConfirmationComponent } from '../shared';


/**
* Canvas view component
*/
@Component({
	selector: 'canvas3d',
	templateUrl: './canvas3d.component.html'
})
export class Canvas3DComponent extends BaseComponent implements OnInit, OnDestroy {

	isDemoUser: boolean;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
		private mainCanvLoadSrv: MainCanvasLoaderService,
		private userSrv: UserService,
		private modalSrv: NgbModal,
		private loaderSrv: LoaderService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.userSrv.isDemoUser().subscribe(res => this.isDemoUser = res);
		this.initCanvas();
	}

	private initCanvas(): void {
		this.loaderSrv.show();
		this.mainCanvLoadSrv.init().subscribe({
			next: () => this.canvasSrv.addEventListener('onResult', this.imagesLoadedEvent),
			error: (err: Error) => {
				this.loaderSrv.hide();
				this.handlePlanError(err);
			}
		});
	}

	/**
	* Check if is Ap view active in multiple state
	*/
	get isMultipleAP(): boolean {
		return this.activeViewState === this.activeViewStateEnum.multiple && this.activeView === this.activeViewEnum.AP;
	}

	/**
	* Event listener for update after images loaded.
	*/
	private imagesLoadedEvent = (event) => {
		if (event.args == BridgeResultMessagesBase.imagesLoaded) {
			this.loaderSrv.hide();
		}
	};

	/**
	* Fit to view the current active view
	*/
	fitToView(): void {
		this.canvasSrv.dispatch("fitToView");
	}

	/**
	* Zoom in the current active view
	*/
	zoomIn(): void {
		this.canvasSrv.dispatch("zoomIn");
	}

	/**
	* Zoom out the current active view
	*/
	zoomOut(): void {
		this.canvasSrv.dispatch("zoomOut");
	}

	private handlePlanError(err: Error): void {
		switch (err.message) {
			case 'PlanNotFound': {
				this.openInvalidPlanModal(this.labels['PLAN_DELETED_WARNING_MESSAGE']);
				break;
			}
			case 'PlanNotValid': {
				this.openInvalidPlanModal(this.labels['INVALID_PLAN_MODAL_MESSAGE']);
				break;
			}
			case 'WebglDisabled': {
				this.openInvalidPlanModal(this.labels['WEBGL_INVALID_WARNING_MESSAGE']);
				break;
			}
			case 'ScreenResolutionInvalid': {
				this.openInvalidPlanModal(this.labels['RESOLUTION_INVALID_WARNING_MESSAGE']);
				break;
			}
			default:
				throw err;
		}
	}

	private openInvalidPlanModal(message: string): void {
		const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
			centered: true, backdrop: 'static'
		});
		(modalRef.componentInstance as ConfirmationComponent).config = {
			title: this.langSrv.getLabels()['INVALID_PLAN_MODAL_TITLE'],
			message,
			confirmBtn: this.langSrv.getLabels()['INVALID_PLAN_MODAL_CONFIRM_BTN'],
			isCancelBtn: false
		};
		modalRef.result.then(() => window.location.href = environment.patplanSite);
	}

	ngOnDestroy(): void {
		this.canvasSrv.removeEventListener('onResult', this.imagesLoadedEvent);
	}
}
