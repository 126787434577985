<!-- Box table -->
<div class="box-table">
  <span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
    {{ labels.APEX_TABLE_TITLE }}
    <div class="fake-material-icon icon-expand expand"></div>
  </span>
  <table class="table" [ngbCollapse]="isCollapsed">
    <thead>
      <tr>
        <th></th>
        <th *ngIf="hasAP">{{ labels.APEX_TABLE_AP_HEADER }}</th>
        <th *ngIf="hasLT">{{ labels.APEX_TABLE_LT_HEADER }}</th>
      </tr>
    </thead>
    <tbody>
      <tr id="apexFemur" *ngIf="isFemur || isLongLeg">
        <td>{{ labels.APEX_TABLE_FEMUR }}</td>
        <td *ngIf="hasAP">{{ apFemurMeasure }}</td>
        <td *ngIf="hasLT"></td>
      </tr>
      <tr id="apexTibia" *ngIf="isTibia || isLongLeg">
        <td>{{ labels.APEX_TABLE_TIBIA }}</td>
        <td *ngIf="hasAP">{{ apTibiaMeasure }}</td>
        <td *ngIf="hasLT">{{ ltTibiaMeasure }}</td>
      </tr>
    </tbody>
  </table>


  <!--AXIAL ROTATION-->
    <table class="table" [ngbCollapse]="isCollapsed">
      <thead>
        <tr>
          <th colspan="2">{{ labels.APEX_TABLE_AXIAL_ROTATION_TITLE }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isFemur || isLongLeg">
          <td>{{ labels.APEX_TABLE_FEMUR }}</td>
          <td>{{ def_femur_axialRotation }} {{ def_femur_axialRotationSign }}</td>
        </tr>
        <tr id="apexTibia" *ngIf="isTibia || isLongLeg">
          <td>{{ labels.APEX_TABLE_TIBIA }}</td>
          <td>{{ def_tibia_axialRotation }} {{ def_tibia_axialRotationSign }}</td>
        </tr>
      </tbody>
    </table>
</div>
