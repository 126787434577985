import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from './base-component';
import { ClosureModalComponent } from './closure-modal';
import { ConfirmationComponent } from './confirmation';
import { CustomTooltipDirective } from './custom-tooltip.directive';
import { HelpComponent } from './help';
import { BoneTypeLabelPipe } from './pipe';


@NgModule({
  declarations: [
    BaseComponent,
    ConfirmationComponent,
    ClosureModalComponent,
    HelpComponent,
    CustomTooltipDirective,
    BoneTypeLabelPipe
  ],
  exports: [
    BaseComponent,
    CustomTooltipDirective,
    BoneTypeLabelPipe
  ],
  entryComponents: [
    ConfirmationComponent,
    ClosureModalComponent,
    HelpComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModalModule,
    NgbTooltipModule
  ],
  providers: []
})
export class SharedModule { }
