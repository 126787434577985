import { Component } from '@angular/core';
import { SelectedApexMech, ViewStateType, ViewType } from '@ortho-next/nextray-core';
import { LayerElements, MeasuresRPM, Messages } from '../../../nextray/Models/AppModel';
import { AxialRotationEnum, NextRayMeasuresDeformityAP, NextRayMeasuresDeformityLT } from '../../../nextray/Models/DeformityAppModel';
import { StateDescription, StateTypes, WorkflowRunning } from '../../../nextray/States/State';
import { PrintMeasures } from '../../../nextray/Utils/PrintUtils';
import { AnatomicalSideEnum, BoneTypeEnum, Fitbone, LanguageService } from '../../core';
import { CanvasService } from '../../services';


/**
* Base component to share common features in every component
*/
@Component({
  selector: 'base-component',
  templateUrl: './base.component.html'
})
export class BaseComponent {

  private _labels: any = {};

  constructor(private languageSrv: LanguageService, private _canvasSrv?: CanvasService) {
    this._labels = languageSrv.getLabels();
  }

  // LANGUAGE DATA //

  /**
  * Get labels
  */
  get labels() {
    return this._labels;
  }


  // CANVAS ENUM //

  workflowEnum = WorkflowRunning;
  activeViewEnum = ViewType;
  activeViewStateEnum = ViewStateType;
  stateTypeEnum = StateTypes;

  sideEnum = AnatomicalSideEnum;


  // CANVAS DATA //

  /**
  * Get mobile device flag.
  */
  get isMobile(): boolean {
    return this._canvasSrv.isMobile;
  }

  /**
  * Check if current case has AP image
  */
  get hasAP(): boolean {
    return this._canvasSrv.hasAP;
  }

  /**
  * Check if current case has Lateral image
  */
  get hasLT(): boolean {
    return this._canvasSrv.hasLT;
  }

  /**
  * Check if current case has Long Leg as bone type
  */
  get isLongLeg(): boolean {
    return this._canvasSrv.isLongLeg;
  }

  /**
  * Check if current case has Femur as bone type
  */
  get isFemur(): boolean {
    return this._canvasSrv.isFemur;
  }

  /**
  * Check if current case has Tibia as bone type
  */
  get isTibia(): boolean {
    return this._canvasSrv.isTibia;
  }

  /**
  * Check if current case has left as bone side
  */
  get isLeft(): boolean {
    return this._canvasSrv.isLeft;
  }

  /**
  * Check if current case has right as bone side
  */
  get isRight(): boolean {
    return this._canvasSrv.isRight;
  }

  /**
  * Get current state description
  */
  get stateDescription(): StateDescription {
    return this._canvasSrv ? this._canvasSrv.stateDescription : null;
  }

  /**
  * Get current state type
  */
  get stateType(): StateTypes {
    return this._canvasSrv ? this._canvasSrv.stateType : null;
  }

  /**
  * Get current state view, i.e AP, Lateral or Multiple
  */
  get activeViewState(): ViewStateType {
    return this._canvasSrv ? this._canvasSrv.activeViewState : null;
  }

  /**
  * Get current active view, i.e AP or Lateral view
  */
  get activeView(): ViewType {
    return this._canvasSrv ? this._canvasSrv.activeView : null;
  }

  /**
  * Get current deformity measures of mechanical axis in AP View.
  */
  get defAPMeasures(): NextRayMeasuresDeformityAP {
    return this._canvasSrv.defAPMeasures;
  }

  /**
  * Get current deformity measures of mechanical axis in Lateral View.
  */
  get defLTMeasures(): NextRayMeasuresDeformityLT {
    return this._canvasSrv.defLTMeasures;
  }

  /**
  * Get current deformity measures of contralateral axis.
  */
  get defCLMeasures(): NextRayMeasuresDeformityAP {
    return this._canvasSrv.defCLMeasures;
  }

  /**
  * Get current scale factor on AP image
  */
  get scaleFactorAP(): number {
    return this._canvasSrv.scaleFactorAP;
  }

  /**
  * Get current scale factor on Lateral image
  */
  get scaleFactorLT(): number {
    return this._canvasSrv.scaleFactorLT;
  }

  /**
  * Get current layers state
  */
  get layers(): LayerElements {
    return this._canvasSrv.layers;
  }

  /**
  * Get current layers values.
  */
  get layersValue(): LayerElements {
    return this._canvasSrv.layersValue;
  }

  /**
  * Check if layers component is visible
  */
  get layersVisible(): boolean {
    return this._canvasSrv.layersVisible;
  }

  /**
  * Get current correction measures
  */
  get rpmMeasures(): MeasuresRPM {
    return this._canvasSrv.rpmMeasures;
  }

  /**
  * Get correction measures for print report
  */
  get printMeasures(): PrintMeasures {
    return this._canvasSrv.printMeasures;
  }

  /**
  * Get messages visibility.
  */
  get messages(): Messages {
    return this._canvasSrv.messages;
  }

  /**
  * Check if eoc cut is active.
  */
  get isEocCut(): boolean {
    return this._canvasSrv.isEocCut;
  }

  /**
  * Get selected apex.
  */
  get selectedApex(): SelectedApexMech {
    return this._canvasSrv.selectedApex;
  }

  /**
   * Indicates if the fitbone is inserted.
   */
  public get isFitboneInserted(): boolean {
    return this._canvasSrv.isFitboneInserted;
  }

  /**
   * Get fitbone data.
   */
  public get fitbone(): Fitbone {
    return this._canvasSrv.fitbone;
  }

  /**
   * Indicates if stroke is locked.
   */
  public get isStrokeLocked(): boolean {
    return this._canvasSrv.isStrokeLocked;
  }

  /**
   * Indicates if the insertion point is inserted.
   */
  public get isInsPointInserted(): boolean {
    return this._canvasSrv.isInsPointInserted;
  }

  /**
  * Get axial roatation value of femur.
  */
  get def_femur_axialRotation(): number {
    return this._canvasSrv.def_femur_axialRotation;
  }

  /**
  * Get axial roatation sign of femur.
  */
  get def_femur_axialRotationSign(): AxialRotationEnum {
    return this._canvasSrv.def_femur_axialRotationSign;
  }

  /**
  * Get axial roatation value of tibia.
  */
  get def_tibia_axialRotation(): number {
    return this._canvasSrv.def_tibia_axialRotation;
  }

  /**
  * Get axial roatation sign of tibia.
  */
  get def_tibia_axialRotationSign(): AxialRotationEnum {
    return this._canvasSrv.def_tibia_axialRotationSign;
  }

  /**
  * Get standard angle of LPFA.
  */
  get def_standardAngles_LPFA(): number {
    return this._canvasSrv.def_standardAngles_LPFA;
  }

  /**
  * Get standard angle of mLDFA.
  */
  get def_standardAngles_mLDFA(): number {
    return this._canvasSrv.def_standardAngles_mLDFA;
  }

  /**
  * Get standard angle of MPTA.
  */
  get def_standardAngles_MPTA(): number {
    return this._canvasSrv.def_standardAngles_MPTA;
  }

  /**
  * Get standard angle of LDTA.
  */
  get def_standardAngles_LDTA(): number {
    return this._canvasSrv.def_standardAngles_LDTA;
  }

  /**
  * Get standard angle of PPTA.
  */
  get def_standardAngles_PPTA(): number {
    return this._canvasSrv.def_standardAngles_PPTA;
  }

  /**
  * Get standard angle of ADTA.
  */
  get def_standardAngles_ADTA(): number {
    return this._canvasSrv.def_standardAngles_ADTA;
  }

  /**
   * Get brightness of AP image.
   */
  public get brightnessAP(): number {
    return this._canvasSrv.brightnessAP;
  }

  /**
   * Get brightness of Lateral image.
   */
  public get brightnessLT(): number {
    return this._canvasSrv.brightnessLT;
  }

  /**
   * Get contrast of AP image.
   */
  public get contrastAP(): number {
    return this._canvasSrv.contrastAP;
  }

  /**
   * Get contrast of Lateral image.
   */
  public get contrastLT(): number {
    return this._canvasSrv.contrastLT;
  }

  /**
   * Gets bone type of Lateral view.
   */
  public get boneTypeLT(): BoneTypeEnum {
    return this._canvasSrv.boneTypeLT;
  }

  /**
   * Indicates if viewer is visible.
   */
  public get isViewerVisible(): boolean {
    return this._canvasSrv.isViewerVisible;
  }

  /**
   * Indicates if blocking screw is selected in AP view.
   */
  public get isBlockingScrewSelectedAP(): boolean {
    return this._canvasSrv.isBlockingScrewSelectedAP;
  }

  /**
   * Indicates if blocking screw is selected in LT view.
   */
  public get isBlockingScrewSelectedLT(): boolean {
    return this._canvasSrv.isBlockingScrewSelectedLT;
  }

  /**
   * Indicates if number of blocking screws is maximum in AP view.
   */
  public get isBlockingScrewFullAP(): boolean {
    return this._canvasSrv.isBlockingScrewFullAP;
  }

  /**
   * Indicates if number of blocking screws is maximum in LT view.
   */
  public get isBlockingScrewFullLT(): boolean {
    return this._canvasSrv.isBlockingScrewFullLT;
  }

  /**
   * Indicates if measurement tool is selected in AP view.
   */
  public get isMeasurementToolSelectedAP(): boolean {
    return this._canvasSrv.isMeasurementToolSelectedAP;
  }

  /**
   * Indicates if measurement tool is selected in LT view.
   */
  public get isMeasurementToolSelectedLT(): boolean {
    return this._canvasSrv.isMeasurementToolSelectedLT;
  }

  /**
   * Indicates if app is downloading a fitbone in AP view.
   */
  public get isFitboneLoadingAP(): boolean {
    return this._canvasSrv.isFitboneLoadingAP;
  }

  /**
  * Indicates if app is downloading a fitbone in lateral view.
  */
  public get isFitboneLoadingLT(): boolean {
    return this._canvasSrv.isFitboneLoadingLT;
  }

}
