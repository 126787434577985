import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


/**
 * This service handles lables and validation messages.
 */
@Injectable()
export class LanguageService {

	private _labels: any = {};
	private _validationMsg: any = {};

	constructor(private http: HttpClient) { }

	/**
	* Get labels
	*/
	getLabels(): any {
		return this._labels;
	}

	/**
	* Get label
	*/
	getLabel(id: string): string {
		return this._labels[id];
	}

	/**
	* Get validation messages
	*/
	getValidationMessages(): any {
		return this._validationMsg;
	}

	/**
	* Load labels and validation messages 
	*/
	loadLabels(): Observable<any> {
		return combineLatest(
			this.http.get("assets/labels.json"),
			this.http.get("assets/validation-messages.json")
		).pipe(
			tap(([labels, validationMsg]) => {
				this._labels = labels;
				this._validationMsg = validationMsg
			})
		);
	}

}
