<div class="page-print a3-portrait">
  <div *ngIf="isDemo"
    style="position:absolute; z-index:9999; height:1900px; width:1350px; display: flex; align-items: center; justify-content: center">
    <p
      style="color:rgba(211, 211, 211, 0.6);font-size: 130px;transform: rotate(330deg); -webkit-transform:rotate(330deg);">
      NOT FOR CLINICAL USE
    </p>
  </div>
  <div class="menu-print" style="padding-top: .3cm;">
    <div class="voice-menu" style="padding-left: 25%">
      <div class="row justify-content-center" style="font-size: 15pt;padding-bottom: 8px">
        <strong>{{labels.FITBONE_PREOP_PLAN_GEN_BY}} {{drFullName}}</strong>
      </div>
      <span><strong>{{labels.PRT_OR_PLAN_COMPONENT_PATIENT_ID}}</strong> {{currentCase?.patient.number}}</span>
      <span><strong>{{labels.PRT_OR_PLAN_COMPONENT_PLAN_ID}}</strong> {{currentCase?.number}}</span>
      <span><strong>{{labels.PRT_OR_PLAN_COMPONENT_PLAN_SIDE}}</strong> {{currentCase?.side}}</span>
      <span><strong>{{labels.PRT_OR_PLAN_COMPONENT_PLAN_BONE_TYPE}}</strong>
        {{currentCase?.boneType | boneTypeLabel}}</span>
      <span><strong>{{ today | date:'medium'}}</strong></span>
    </div>
    <span class="logo-text">{{labels.APP_LOGO_TEXT}}</span>
  </div>
  <div class="wrapper-content">
    <div class="row justify-content-center" *ngIf="isViewTypeAP">
      <table class="wrapper-print-table fitbone-implant-measures">
        <tbody>
          <tr>
            <td>
              <strong>{{labels.IMPLANT}}:</strong> {{implant}} ({{ fitboneLength }} mm)
            </td>
            <td>
              <strong>{{ targetBoneLengthLbl }}:</strong> {{ maLength }}
            </td>
            <td *ngIf="mLDFA">
              <strong>{{labels.MLDFA}}:</strong> {{mLDFA}}°
            </td>
            <td *ngIf="MPTA">
              <strong>{{labels.MA_AP_TABLE_COMPONENT_MPTA}}:</strong> {{MPTA}}°
            </td>
            <td *ngIf="axisCorrection">
              <strong>{{labels.AXIS_CORRECTION}}:</strong> {{axisCorrection}}
            </td>
            <td *ngIf="femurAxialRotation">
              <strong>{{labels.ROTATION_CORRECTION}}:</strong> {{ femurAxialRotation }}
            </td>
            <td *ngIf="tibiaAxialRotation">
              <strong>{{labels.ROTATION_CORRECTION}}:</strong> {{ tibiaAxialRotation }}
            </td>
            <td *ngIf="osteotomyLevel">
              <strong>{{labels.OSTEOTOMY}}:</strong> {{ osteotomyLevel }} mm
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row justify-content-center" style="position: relative;">
      <hr *ngIf="isViewTypeAP" [ngStyle]="{'top.px': lineOffset}"
        style="position:absolute;border: 1px solid red;width: 100%;margin: 0;padding: 0;">
      <hr *ngIf="isViewTypeAP" [ngStyle]="{'top.px': FHPropAPlineOffset}"
        style="position:absolute;border: 1px solid red;width: 100%;margin: 0;padding: 0;">
      <hr *ngIf="isViewTypeAP" [ngStyle]="{'top.px': CAPropAPlineOffset}"
        style="position:absolute;border: 1px solid red;width: 100%;margin: 0;padding: 0;">
      <img [src]="defAnalysSrc" width="434" height="1749" />
      <img [src]="fitboneCloseScr" width="434" height="1749" />
      <img [src]="fitboneOpenScr" width="434" height="1749" />
    </div>
  </div>
</div>
