import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { ApexTableComponent } from './apex-table';
import { LldTableComponent } from './lld-table';
import { MaApTableComponent, MaLtTableComponent } from './ma-table';

@NgModule({
  declarations: [
    ApexTableComponent,
    MaApTableComponent,
    MaLtTableComponent,
    LldTableComponent
  ],
  exports: [
    ApexTableComponent,
    MaApTableComponent,
    MaLtTableComponent,
    LldTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbCollapseModule
  ]
})
export class DeformityModule { }
