import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SelectedApexMech, ViewStateType } from '@ortho-next/nextray-core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { bindedModel } from '../../nextray/Models/BindedModel';
import { AnatomicalSideEnum } from '../core';
import { CanvasService } from './canvas.service';


/**
* This service handles help modal
*/
@Injectable()
export class HelpService {

  private fileNameList: any;
  private _isLoaded: boolean;

  constructor(
    private http: HttpClient,
    private canvasSrv: CanvasService,
    private sanitizer: DomSanitizer
  ) { }

  /**
  * Check if help file name list is loaded
  */
  get isLoaded(): boolean {
    return this._isLoaded;
  }

  /**
  * Load help file name list
  */
  loadFileNames(): Observable<any> {
    return this.http.get(environment.helpSite.fileNames).pipe(tap(list => {
      this.fileNameList = list;
      this._isLoaded = true;
    }));
  }

  /**
  * Get safe url of help
  */
  get safeUrl(): SafeUrl {
    const url = this.helpUrl;
    return url ? this.sanitizer.bypassSecurityTrustResourceUrl(url) : null;
  }


  private get helpUrl(): string {
    const fileName = this.fileNameList ? this.fileNameList[this.fileKey] : null;
    return fileName ? `${environment.helpSite.baseUrl}/${fileName}.html` : null;
  }

  private get fileKey(): string {
    if (this.canvasSrv.isStrokeLocked) { //Before Insertion
      return `STROKE_${this.boneType}_${this.side}_${this.activeView}_${this.insApproach}`;
    }
    else if (this.canvasSrv.isFitboneInserted) { // FitBone Selection Completed
      return `FITBONE_${this.boneType}_${this.side}_${this.activeView}_${this.insApproach}`;
    }
    else if (this.canvasSrv.isEocCut) { // CUT completed
      return `CUT_${this.boneType}_${this.side}_${this.activeView}_${this.insApproach}`;
    }
    else if (this.canvasSrv.isInsPointInserted && !bindedModel.isInsertionPointEnabled) { // Insertion Completed
      return `INSERTION_${this.boneType}_${this.side}_${this.activeView}_${this.insApproach}`;
    } else { // Before Insertion
      return `BEFORE_${this.boneType}_${this.side}_${this.activeView}`;
    }
  }

  private get boneType(): string {
    return 'LONGLEG';
  }

  private get side(): string {
    if (!this.canvasSrv.currentCase) return '';
    switch (this.canvasSrv.currentCase.side) {
      case AnatomicalSideEnum.Left: return 'LEFT';
      case AnatomicalSideEnum.Right: return 'RIGHT';
      default: return '';
    }
  }

  private get activeView(): string {
    switch (this.canvasSrv.activeViewState) {
      case ViewStateType.AP: return 'AP';
      case ViewStateType.LT: return 'LAT';
      case ViewStateType.multiple: return 'MUL';
      default: return '';
    }
  }

  private get insApproach(): string {
    switch (this.canvasSrv.selectedApex) {
      case SelectedApexMech.femurProximal: return 'ANTEGRADE_FEMUR'
      case SelectedApexMech.femurDistal: return 'RETROGRADE_FEMUR';
      case SelectedApexMech.tibiaProximal: return 'ANTEGRADE_TIBIA';
      default: return '';
    }
  }

}
