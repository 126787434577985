import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AuthCoreModule } from '@ortho-next/auth-core';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from './app.component';
import { AUTH_CONFIG } from './app.config';
import { APP_ROUTES } from './app.routes';
import { FallbackComponent, LanguageService, LoaderService, MonitoringErrorHandler, MonitoringService } from './core';
import { ErrorsComponent } from './core/components/errors/errors.component';
import { CaseRepository, ImageRepository, ModelRepository, ProductRepository, UserRepository } from './core/repositories';
import { PrintModule } from './print';
import { CanvasService, CaseService, HelpService, ImageService, MainCanvasLoaderService, ModelService, PrinterService, ProductService, UserService } from './services';
import { SharedModule } from './shared';
import { TemplateModule } from './template';


export function initApp(langSrv: LanguageService) {
	return (): Promise<any> => {
		return langSrv.loadLabels().toPromise();
	};
};


@NgModule({
	declarations: [
		AppComponent,
		ErrorsComponent,
		FallbackComponent
	],
	imports: [
		FormsModule,
		BrowserModule,
		AuthCoreModule.forRoot(AUTH_CONFIG),
		RouterModule.forRoot(APP_ROUTES),
		CommonModule,
		ReactiveFormsModule,
		TemplateModule,
		HttpClientModule,
		SharedModule,
		PrintModule
	],
	providers: [
		CaseRepository,
		ImageRepository,
		CaseService,
		ImageService,
		MainCanvasLoaderService,
		CanvasService,
		CookieService,
		MonitoringService,
		LoaderService,
		UserService,
		UserRepository,
		ModelService,
		ModelRepository,
		ProductRepository,
		ProductService,
		HelpService,
		PrinterService,
		{
			provide: ErrorHandler,
			useClass: MonitoringErrorHandler
		},
		LanguageService,
		{
			provide: APP_INITIALIZER, useFactory: initApp, deps: [LanguageService], multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
