<div class="page-print a3-portrait">
  <div *ngIf="isDemo"
    style="position:absolute; z-index:9999; height:1900px; width:1350px; display: flex; align-items: center; justify-content: center">
    <p
      style="color:rgba(211, 211, 211, 0.6);font-size: 130px;transform: rotate(330deg); -webkit-transform:rotate(330deg);">
      NOT FOR CLINICAL USE
    </p>
  </div>
  <div class="menu-print" style="padding-top: .3cm;">
    <div class="voice-menu" style="padding-left: 25%">
      <div class="row justify-content-center" style="font-size: 15pt;">
        <strong>{{labels.FITBONE_PREOP_PLAN_GEN_BY}} {{drFullName}}</strong>
      </div>
      <span><strong>{{labels.PRT_OR_PLAN_COMPONENT_PATIENT_ID}}</strong> {{currentCase?.patient.number}}</span>
      <span><strong>{{labels.PRT_OR_PLAN_COMPONENT_PLAN_ID}}</strong> {{currentCase?.number}}</span>
      <span><strong>{{labels.PRT_OR_PLAN_COMPONENT_PLAN_SIDE}}</strong> {{currentCase?.side}}</span>
      <span><strong>{{labels.PRT_OR_PLAN_COMPONENT_PLAN_BONE_TYPE}}</strong>
        {{currentCase?.boneType | boneTypeLabel}}</span>
      <span><strong>{{ today | date:'medium'}}</strong></span>
    </div>
    <span class="logo-text">{{labels.APP_LOGO_TEXT}}</span>
  </div>
  <div class="wrapper-content">
    <div class="row">
      <div class="col-7">
        <table class="wrapper-print-table fitbone-measures cc2 cc3 danube">
          <thead>
            <tr>
              <th colspan="3">{{ labels.AP_PARAM_BEFOR_CORRECT }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ labels.PARAMETER }}</td>
              <td>{{ labels.MA_AP_TABLE_COMPONENT_VALUE_HEADER }}</td>
              <td>{{ labels.CONTRALATERAL }}</td>
            </tr>
            <tr>
              <td>{{ labels.MAD_MM }} {{ rangeMAD }}</td>
              <td>{{ defAPMeasures.def_ap_MAD }}</td>
              <td>{{ defCLMeasures.def_ap_MAD_ctrlateral }}</td>
            </tr>
            <tr>
              <td>{{ labels.MNSA_DEGREE }} {{ rangeMNSA }}</td>
              <td>{{ defAPMeasures.def_ap_MNSA }}</td>
              <td>{{ defCLMeasures.def_ap_MNSA_ctrlateral }}</td>
            </tr>
            <tr>
              <td>{{ labels.LPFA_DEGREE }} {{ rangeLPFA }}</td>
              <td>{{ defAPMeasures.def_ap_LPFA }}</td>
              <td>{{ defCLMeasures.def_ap_LPFA_ctrlateral }}</td>
            </tr>
            <tr>
              <td>{{ labels.MPFA_DEGREE }} {{ rangeMPFA }}</td>
              <td>{{ defAPMeasures.def_ap_MPFA }}</td>
              <td>{{ defCLMeasures.def_ap_MPFA_ctrlateral }}</td>
            </tr>
            <tr>
              <td>{{ labels.MLDFA_DEGREE }} {{ rangeMLDFA }}</td>
              <td>{{ defAPMeasures.def_ap_mLDFA }}</td>
              <td>{{ defCLMeasures.def_ap_mLDFA_ctrlateral }}</td>
            </tr>
            <tr>
              <td>{{ labels.KNEE_JLCA_DEGREE }} {{ rangeJLCA }}</td>
              <td>{{ defAPMeasures.def_ap_JLCA }}</td>
              <td>{{ defCLMeasures.def_ap_JLCA_ctrlateral }}</td>
            </tr>
            <tr>
              <td>{{ labels.KNEE_TRANSLAT_MM }} {{ rangeKneeTransl }}</td>
              <td>{{ defAPMeasures.def_ap_kneeTranslation }}</td>
              <td>{{ defCLMeasures.def_ap_kneeTranslation_ctrlateral }}</td>
            </tr>
            <tr>
              <td>{{ labels.MPTA_DEGREE }} {{ rangeMPTA }}</td>
              <td>{{ defAPMeasures.def_ap_MPTA }}</td>
              <td>{{ defCLMeasures.def_ap_MPTA_ctrlateral }}</td>
            </tr>
            <tr>
              <td>{{ labels.LDTA_DEGREE }} {{ rangeLDTA }}</td>
              <td>{{ defAPMeasures.def_ap_LDTA }}</td>
              <td>{{ defCLMeasures.def_ap_LDTA_ctrlateral }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-5">
        <table class="wrapper-print-table fitbone-measures cc1 green-fern">
          <thead>
            <tr>
              <th>{{ labels.AP_PARAM_AFTER_CORRECT }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ labels.MA_AP_TABLE_COMPONENT_VALUE_HEADER }}</td>
            </tr>
            <tr>
              <td>{{ rpmMeasures.rpm_ap_MAD }}</td>
            </tr>
            <tr>
              <td>{{ rpmMeasures.rpm_ap_MNSA }}</td>
            </tr>
            <tr>
              <td>{{ rpmMeasures.rpm_ap_LPFA }}</td>
            </tr>
            <tr>
              <td>{{ rpmMeasures.rpm_ap_MPFA }}</td>
            </tr>
            <tr>
              <td>{{ rpmMeasures.rpm_ap_mLDFA }}</td>
            </tr>
            <tr>
              <td>{{ rpmMeasures.rpm_ap_JLCA }}</td>
            </tr>
            <tr>
              <td>{{ rpmMeasures.rpm_ap_kneeTranslation }}</td>
            </tr>
            <tr>
              <td>{{ rpmMeasures.rpm_ap_MPTA }}</td>
            </tr>
            <tr>
              <td>{{ rpmMeasures.rpm_ap_LDTA }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-7">
        <table class="wrapper-print-table fitbone-measures cc2 danube">
          <thead>
            <tr>
              <th colspan="2">{{ labels.LAT_PARAM_BEFOR_CORRECT }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ labels.PARAMETER }}</td>
              <td>{{ labels.MA_AP_TABLE_COMPONENT_VALUE_HEADER }}</td>
            </tr>
            <tr>
              <td>{{ labels.PDFA_DEGREE }} {{ rangePDFA }}</td>
              <td>{{ defLTMeasures.def_lt_PDFA }}</td>
            </tr>
            <tr>
              <td>{{ labels.KNEE_TRANSLAT_MM }} {{ rangeKneeTransl }}</td>
              <td>{{ defLTMeasures.def_lt_kneeTranslation }}</td>
            </tr>
            <tr>
              <td>{{ labels.PPTA_DEGREE }} {{ rangePPTA }}</td>
              <td>{{ defLTMeasures.def_lt_PPTA }}</td>
            </tr>
            <tr>
              <td>{{ labels.ADTA_DEGREE }} {{ rangeADTA }}</td>
              <td>{{ defLTMeasures.def_lt_ADTA }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-5">
        <table class="wrapper-print-table fitbone-measures cc1 green-fern">
          <thead>
            <tr>
              <th>{{ labels.LAT_PARAM_AFTER_CORRECT }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ labels.MA_AP_TABLE_COMPONENT_VALUE_HEADER }}</td>
            </tr>
            <tr>
              <td>{{ rpmMeasures.rpm_lt_PDFA }}</td>
            </tr>
            <tr>
              <td>{{ rpmMeasures.rpm_lt_kneeTranslation }}</td>
            </tr>
            <tr>
              <td>{{ rpmMeasures.rpm_lt_PPTA }}</td>
            </tr>
            <tr>
              <td>{{ rpmMeasures.rpm_lt_ADTA }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-7">
        <table class="wrapper-print-table fitbone-measures cc2 cc3 cc4 danube">
          <thead>
            <tr>
              <th colspan="4">{{ labels.LLD_DISCREPA_BEFOR_CORRECT }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ labels.BONE }}</td>
              <td>{{ labels.MA_AP_TABLE_COMPONENT_VALUE_HEADER }}</td>
              <td>{{ labels.CONTRALATERAL }}</td>
              <td>{{ labels.DISCREPANCY }}</td>
            </tr>
            <tr>
              <td>{{ labels.LONG_LEG_MM }}</td>
              <td>{{ defAPMeasures.def_ap_fullLength }}</td>
              <td>{{ defCLMeasures.def_ap_fullLength_ctrlateral }}</td>
              <td>{{ longlegLLD }}</td>
            </tr>
            <tr>
              <td>{{ labels.FEMUR_MM }}</td>
              <td>{{ defAPMeasures.def_ap_femurLength }}</td>
              <td>{{ defCLMeasures.def_ap_femurLength_ctrlateral }}</td>
              <td>{{ femurLLD }}</td>
            </tr>
            <tr>
              <td>{{ labels.TIBIA_MM }}</td>
              <td>{{ defAPMeasures?.def_ap_tibiaLength }}</td>
              <td>{{ defCLMeasures?.def_ap_tibiaLength_ctrlateral }}</td>
              <td>{{ tibiaLLD }}</td>
            </tr>
            <tr>
              <td>{{ labels.FEMUR_TIBIA_MM }}</td>
              <td>{{ trueLength }}</td>
              <td>{{ trueLengthCL }}</td>
              <td>{{ trueLengthLLD }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-5">
        <table class="wrapper-print-table fitbone-measures cc1 cc2 green-fern">
          <thead>
            <tr>
              <th colspan="2">{{ labels.LLD_DISCREPA_AFTER_CORRECT }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ labels.MA_AP_TABLE_COMPONENT_VALUE_HEADER }}</td>
              <td>{{ labels.DISCREPANCY }}</td>
            </tr>
            <tr>
              <td>{{ rpmMeasures?.rpm_ap_fullLength }}</td>
              <td>{{ rpmLonglegLLD }}</td>
            </tr>
            <tr>
              <td>{{ rpmMeasures?.rpm_ap_femurLength }}</td>
              <td>{{ rpmFemurLLD }}</td>
            </tr>
            <tr>
              <td>{{ rpmMeasures?.rpm_ap_tibiaLength }}</td>
              <td>{{ rpmTibiaLLD }}</td>
            </tr>
            <tr>
              <td>{{ rpmTrueLength }}</td>
              <td>{{ rpmTrueLengthLLD }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-7">
        <table class="wrapper-print-table fitbone-measures cc2 cc3 green-fern">
          <thead>
            <tr>
              <th colspan="3">{{ labels.WEDGE_TABLE_COMPONENT_TITLE }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ labels.PARAMETER }}</td>
              <td>{{ labels.APEX_TABLE_AP_HEADER }}</td>
              <td>{{ labels.APEX_TABLE_LAT_HEADER }}</td>
            </tr>
            <tr>
              <td>{{ labels.WEDGE_TABLE_COMPONENT_WEDGE_ANGLE }}</td>
              <td>{{ ap_wedgeAngle }}</td>
              <td>{{ lt_wedgeAngle }}</td>
            </tr>
            <tr>
              <td>{{ labels.WEDGE_TABLE_COMPONENT_CORTEX_LENGTH }}</td>
              <td>{{ ap_cortexLength }}</td>
              <td>{{ lt_cortexLength }}</td>
            </tr>
            <tr>
              <td>{{ labels.WEDGE_TABLE_COMPONENT_AXIAL_LENGTH }}</td>
              <td>{{ ap_axialLength }}</td>
              <td>{{ lt_axialLength }}</td>
            </tr>
            <tr>
              <td>{{ labels.WEDGE_TABLE_COMPONENT_SECONDARY_TRANSLATION }}</td>
              <td>{{ ap_secondaryTransl }}</td>
              <td>{{ lt_secondaryTransl }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
